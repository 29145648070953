import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  wrapper: {
    boxShadow: 'none',
    borderRadius: '0px 0px 8px 8px'
  }
}));

export default useStyles;
