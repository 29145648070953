import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  wrapper: {
    display: 'flex',
    width: '140px',
    height: '50px',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    '&:hover': {
      borderRadius: '0px !important',
      backgroundColor: '#FFECEC'
    }
  },
  tab: {
    color: '#374151 !important',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '19.1px',
    textDecoration: 'none !important'
  },
  activeWrapper: {
    display: 'flex',
    height: '50px',
    width: '140px',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: '#FFD1D1'
  },
  active: {
    color: `${theme.palette.primary.main} !important`,
    fontWeight: 800,
    opacity: 1,
    fontSize: '14px',
    lineHeight: '16px',
    textDecoration: 'none !important'
  },
  container: {
    marginTop: '16px',
    backgroundColor: theme.palette.white,
    borderTopLeftRadius: '8px',
    borderTopRightRadius: '8px'
  }
}));

export default useStyles;
