export const GET_KEY_CUSTOM_FIELDS_SUCCESS = 'GET_KEY_CUSTOM_FIELDS_SUCCESS';
export const GET_SEARCH_RESULT = 'GET_SEARCH_RESULT';
export const GET_SEARCH_RESULT_SUCCESS = 'GET_SEARCH_RESULT_SUCCESS';
export const SET_LOADING = 'SET_LOADING';
export const UPDATE_FILTERS = 'UPDATE_FILTERS';
export const CLEAR_STATE = 'CLEAR_STATE';

export const UPDATE_POSTS_AVAILABLE_FILTERS = 'UPDATE_POSTS_AVAILABLE_FILTERS';
export const GET_PRODUCTS = 'GET_PRODUCTS';
export const SEARCH_POST = 'SEARCH_POST';
export const GET_TAGS_SUCCESS = 'GET_TAGS_SUCCESS';

export const GET_LAST_POSTS = 'GET_LAST_POSTS';
export const GET_LAST_POSTS_SUCCESS = 'GET_LAST_POSTS_SUCCESS';
