import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  popper: {
    zIndex: 1300,
    width: '443px',
    borderRadius: '16px',
    border: '1px solid ',
    backgroundColor: '#FFFFFF',
    padding: '12px 24px',
    borderColor: '#E5E7EB',
    marginTop: '11px !important',
    marginRight: '-31px !important'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  headerGroup: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    '&:hover': {
      cursor: 'default'
    }
  },
  filterItem: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  filterItemTitle: {
    color: '#374151',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16.41px'
  },
  bottomGroup: {
    display: 'flex',
    gap: '37px',
    justifyContent: 'flex-end'
  },
  customButtonContent: {
    fontWeight: 400,
    lineHeight: '22px',
    color: '#111827 !important'
  },
  customButton: {
    borderRadius: '5px'
  },
  filterTitle: {
    color: '#374151',
    fontWeight: 600,
    fontSize: '20px',
    lineHeight: '48px'
  }
  // radioGroup: {
  //   gap: '4px'
  // },
  // listItem: {
  //   padding: '0px',
  //   height: '36px',
  //   display: 'flex',
  //   gap: '8px',
  //   cursor: 'pointer',
  //   '&:hover': {
  //     backgroundColor: 'inherit'
  //   }
  // },
  // labelRow: {
  //   margin: '0px'
  // },
  // radioItem: {
  //   '&.MuiRadio-root': {
  //     padding: '4px'
  //   },
  //   '& .MuiSvgIcon-root': {
  //     fontSize: 20,
  //     color: '#000000'
  //   },
  //   '&.Mui-checked': {
  //     color: '#000000'
  //   },
  //   '&.Mui-disabled': {
  //     color: 'gray',
  //     opacity: 0.5
  //   }
  // },
}));

export default useStyles;
