import React, { useEffect, useRef, useState } from 'react';
import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import CustomTooltip from '../CustomTooltip';
import useStyles from './styles';
import { highlightText } from '../../utils/common';

const DocumentContentCard = ({ data, searchValue }) => {
  const classes = useStyles();
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const titleRef = useRef(null);

  useEffect(() => {
    if (titleRef.current) {
      setIsEllipsisActive(titleRef.current.scrollWidth > titleRef.current.clientWidth);
    }
  }, [data.reportName]);

  return (
    <div className={classes.cardFullWidth}>
      <div className={classes.cardTitleGroup}>
        <img src="/images/elasticsearch/pdf-icon.svg" alt="pdf-icon" />
        <CustomTooltip title={isEllipsisActive ? data.documentName : ''} placement="bottom-start">
          <Link
            className={classes.cardTitleWithWrap}
            href={`/pdf/deal/${data.dealId}/document-pdf/${data.documentId}`}
            underline="none"
            target="_blank"
            ref={titleRef}
          >
            {data.documentName}
          </Link>
        </CustomTooltip>
      </div>
      <div className={classes.cardContent}>
        {Array.from(data.valueMap).map(([key, value]) => (
          <div className={classes.cardPdfContentRow}>
            <div className={classes.filedPage}>Page {key} |</div>
            <div className={classes.filedValue}>{highlightText(value, searchValue)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

DocumentContentCard.propTypes = {
  data: PropTypes.object,
  searchValue: PropTypes.string
};

export default DocumentContentCard;
