import createReducer from '../../../utils/createReducer';
import * as constants from './elastic.constants';

export const initialState = {
  searchResult: [],
  loading: false,
  availableFilters: {
    products: [],
    deals: [],
    currencies: [],
    reportTypes: [],
    customFieldMap: new Map()
  },
  filterValues: {
    products: [],
    deals: [],
    currencies: [],
    reportTypes: [],
    customFieldMap: new Map()
  },
  keyCustomFields: new Map()
};

export default createReducer(initialState, {
  [constants.GET_KEY_CUSTOM_FIELDS_SUCCESS](state, { payload }) {
    return {
      ...state,
      keyCustomFields: payload
    };
  },
  [constants.GET_SEARCH_RESULT](state) {
    return {
      ...state,
      loading: true
    };
  },
  [constants.GET_SEARCH_RESULT_SUCCESS](state, { payload }) {
    console.log(payload);
    const { searchResult, filterUniqueValues, resetValues } = payload;
    return {
      ...state,
      searchResult: resetValues ? searchResult : [...state.searchResult, ...searchResult],
      availableFilters: filterUniqueValues,
      loading: false
    };
  },
  [constants.UPDATE_FILTERS](state, { payload }) {
    return {
      ...state,
      filterValues: payload
    };
  },
  [constants.CLEAR_STATE](state) {
    return {
      ...state,
      searchResult: [],
      availableFilters: {
        products: [],
        deals: [],
        currencies: [],
        reportTypes: [],
        customFieldMap: new Map()
      },
      filterValues: {
        products: [],
        deals: [],
        currencies: [],
        reportTypes: [],
        customFieldMap: new Map()
      }
    };
  },
  [constants.SET_LOADING](state, { payload }) {
    return {
      ...state,
      loading: payload
    };
  }
});
