export const DEALS = '/deal/page/deals';
const LOGIN = '/auth/login';
const SIGN_UP = '/auth/sign-up';
const FORGOT_PASSWORD = '/auth/forgot';
const HOME = '/home';
const PORTFOLIOS = '/deal/page/portfolios';
const TRACK_CHANGES = '/deal/page/track-changes';
const OPERATIONS_USERS = '/operations/user-management';
const OPERATIONS_TOPICS = '/operations/topic-management';
const OPERATIONS_CATEGORIES = '/operations/category-management';
const OPERATIONS_PRIVATE = '/operations/private-content';
const OPERATIONS_ANALYTICS = '/operations/analytics';
const OPERATIONS_WORKFLOW = '/operations/workflow';
const OPERATIONS_PRODUCTS = '/operations/products';
const OPERATIONS_DEAL_TYPES = '/operations/deal-types';
const ABOUT_US = '/about-us';
const REPORTS = '/reports';
const CONTACT_US = '/contact-us';

const titles = {
  [LOGIN]: 'Login - Dealscribe',
  [SIGN_UP]: 'Sign Up - Dealscribe',
  [FORGOT_PASSWORD]: 'Reset password - Dealscribe',
  [HOME]: 'Home - Dealscribe',
  [PORTFOLIOS]: 'Portfolios - Dealscribe',
  [TRACK_CHANGES]: 'Track changes - Dealscribe',
  [OPERATIONS_USERS]: 'Users - Operations - Dealscribe',
  [OPERATIONS_TOPICS]: 'Topics - Operations - Dealscribe',
  [OPERATIONS_CATEGORIES]: 'Categories - Operations - Dealscribe',
  [OPERATIONS_PRIVATE]: 'Private content - Operations - Dealscribe',
  [OPERATIONS_ANALYTICS]: 'Analytics - Operations - Dealscribe',
  [OPERATIONS_WORKFLOW]: 'Workflow - Operations - Dealscribe',
  [OPERATIONS_PRODUCTS]: 'Products - Operations - Dealscribe',
  [OPERATIONS_DEAL_TYPES]: 'Deal Types - Operations - Dealscribe',
  [ABOUT_US]: 'About us - Dealscribe',
  [REPORTS]: 'Reports - Dealscribe',
  [CONTACT_US]: 'Contact us - Dealscribe',
  default: 'Dealscribe'
};

export const getTitle = (location) => titles[location] || titles.default;
