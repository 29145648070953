import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  content: {
    backgroundColor: '#f4f6f8',
    padding: '68px 30px 0px 30px'
  },
  home: {
    padding: '64px 0px 0px 0px',
    overflowX: 'hidden'
  },
  aboutUs: {
    padding: '64px 0px 0px 0px'
  },
  contactUs: {
    padding: '76px 24px 0px 24px'
  },
  errors: {
    padding: '0px'
  }
}));

export default useStyles;
