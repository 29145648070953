import React, { useEffect, useRef, useState } from 'react';
import { Link } from '@mui/material';
import PropTypes from 'prop-types';
import { QuestionAnswerRounded } from '@mui/icons-material';
import CustomTooltip from '../CustomTooltip';
import useStyles from './styles';
import { highlightText } from '../../utils/common';

const TopicCard = ({ data, searchValue }) => {
  const classes = useStyles();
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const titleRef = useRef(null);

  useEffect(() => {
    if (titleRef.current) {
      setIsEllipsisActive(titleRef.current.scrollWidth > titleRef.current.clientWidth);
    }
  }, [data.topicName]);

  return (
    <div className={classes.card}>
      <div className={classes.cardTitleGroup}>
        <QuestionAnswerRounded />
        <CustomTooltip title={isEllipsisActive ? data.topicName : ''} placement="bottom-start">
          <Link
            className={classes.cardTitleWithWrap}
            underline="none"
            target="_blank"
            ref={titleRef}
          >
            {highlightText(data.topicName, searchValue)}
          </Link>
        </CustomTooltip>
      </div>
      <div className={classes.cardContent}>
        <div className={classes.filedValue}>{highlightText(data.question, searchValue)}</div>
        <div className={classes.filedSecondary}>
          {data.categoryName}
          {highlightText(data.categoryName, searchValue)}
        </div>
      </div>
    </div>
  );
};

TopicCard.propTypes = {
  data: PropTypes.object,
  searchValue: PropTypes.string
};

export default TopicCard;
