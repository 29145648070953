import React, { useState } from 'react';
import { Grid, Popper, Typography } from '@mui/material';
import { FilterListOutlined } from '@mui/icons-material';
import PropTypes from 'prop-types';
import useStyles from './styles';
import CustomButton from '../../CustomButton/CustomButton';
import OptionDropdown from '../../OptionDropdown';
import {
  SEARCH_DEALS,
  SEARCH_PDF_CONTENT,
  SEARCH_REPORTS,
  SEARCH_TOPICS
} from '../../../utils/constants/stringConstants';

const ElasticSearchFilter = ({
  availableFilters,
  filterValues,
  loading,
  disabled,
  searchType,
  updateFilterValues,
  performSearch,
  keyCustomFields,
  restoreFilter
}) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClickFilter = (event) => {
    setAnchorEl(event.currentTarget);
    if (open) {
      restoreFilter();
    }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = () => {
    restoreFilter();
    setOpen(false);
  };

  const handleApply = () => {
    performSearch();
    setOpen(false);
  };

  const getFiltersForDealsAndDocuments = () => (
    <>
      <Grid className={classes.filterItem}>
        <Typography className={classes.filterItemTitle}>By product</Typography>
        <OptionDropdown
          loading={loading}
          disabled={loading}
          classes={classes}
          name="products"
          limitTags="true"
          inputLabelProps={{
            shrink: false
          }}
          options={availableFilters.products}
          selectedOptions={filterValues.products}
          updateOptions={(value) => updateFilterValues('products', value)}
          label={filterValues.products && filterValues.products.length > 0 ? '' : 'Select product'}
          size="medium"
        />
      </Grid>
      <Grid className={classes.filterItem}>
        <Typography className={classes.filterItemTitle}>By deal name</Typography>
        <OptionDropdown
          loading={loading}
          disabled={loading}
          classes={classes}
          name="deals"
          limitTags="true"
          inputLabelProps={{
            shrink: false
          }}
          options={availableFilters.deals}
          selectedOptions={filterValues.deals}
          updateOptions={(value) => updateFilterValues('deals', value)}
          label={filterValues.deals && filterValues.deals.length > 0 ? '' : 'Select deal'}
          size="medium"
        />
      </Grid>
      <Grid className={classes.filterItem}>
        <Typography className={classes.filterItemTitle}>By currency</Typography>
        <OptionDropdown
          loading={loading}
          disabled={loading}
          classes={classes}
          name="currencies"
          limitTags="true"
          inputLabelProps={{
            shrink: false
          }}
          options={availableFilters.currencies}
          selectedOptions={filterValues.currencies}
          updateOptions={(value) => updateFilterValues('currencies', value)}
          label={
            filterValues.currencies && filterValues.currencies.length > 0 ? '' : 'Select currency'
          }
          size="medium"
        />
      </Grid>
      {Array.from(keyCustomFields?.entries()).map(([key, value]) => (
        <Grid className={classes.filterItem}>
          <Typography className={classes.filterItemTitle}>{value}</Typography>
          <OptionDropdown
            loading={loading}
            disabled={loading}
            classes={classes}
            name={value}
            limitTags="true"
            inputLabelProps={{
              shrink: false
            }}
            options={availableFilters?.customFieldMap.get(key)?.map((name) => ({
              id: name,
              name
            }))}
            selectedOptions={filterValues.customFieldMap.get(key)}
            updateOptions={(values) => updateFilterValues('customFieldMap', values, key)}
            label={
              filterValues.customFieldMap.has(key) &&
              filterValues.customFieldMap.get(key)?.length > 0
                ? ''
                : `Select ${value}`
            }
            size="medium"
          />
        </Grid>
      ))}
    </>
  );

  const getFiltersForTopics = () => (
    <Grid className={classes.filterItem}>
      <Typography className={classes.filterItemTitle}>By product</Typography>
      <OptionDropdown
        loading={loading}
        disabled={loading}
        classes={classes}
        name="products"
        limitTags="true"
        inputLabelProps={{
          shrink: false
        }}
        options={availableFilters.products}
        selectedOptions={filterValues.products}
        updateOptions={(value) => updateFilterValues('products', value)}
        label={filterValues.products && filterValues.products.length > 0 ? '' : 'Select product'}
        size="medium"
      />
    </Grid>
  );

  const getFiltersForReports = () => (
    <Grid className={classes.filterItem}>
      <Typography className={classes.filterItemTitle}>By report type</Typography>
      <OptionDropdown
        loading={loading}
        disabled={loading}
        classes={classes}
        name="reportTypes"
        limitTags="true"
        inputLabelProps={{
          shrink: false
        }}
        options={availableFilters.reportTypes}
        selectedOptions={filterValues.reportTypes}
        updateOptions={(value) => updateFilterValues('reportTypes', value)}
        label={
          filterValues.reportTypes && filterValues.reportTypes.length > 0
            ? ''
            : 'Select report type'
        }
        size="medium"
      />
    </Grid>
  );

  const getFiltersBySearchType = () => {
    switch (searchType) {
      case SEARCH_DEALS:
        return getFiltersForDealsAndDocuments();
      case SEARCH_PDF_CONTENT:
        return getFiltersForDealsAndDocuments();
      case SEARCH_TOPICS:
        return getFiltersForTopics();
      case SEARCH_REPORTS:
        return getFiltersForReports();
      default:
        return <div />;
    }
  };

  return (
    <>
      <CustomButton
        onClick={handleClickFilter}
        disabled={loading || disabled}
        placeholder="Filter"
        variant="outlined"
        size="medium"
        leftIcon={<FilterListOutlined sx={{ fontSize: 16, color: '#111827' }} />}
        buttonColor="white"
        textColor="secondary"
        outlinedColor="dark-gray"
        classes={classes}
      />
      <Popper
        open={open}
        anchorEl={anchorEl}
        placement="bottom-start"
        disablePortal={false}
        className={classes.popper}
      >
        <div className={classes.content}>
          <div className={classes.headerGroup}>
            <FilterListOutlined sx={{ color: '#111827' }} />
            <Typography className={classes.filterTitle}>Filter</Typography>
          </div>
          {getFiltersBySearchType()}
          <div className={classes.bottomGroup}>
            <CustomButton
              onClick={handleClose}
              placeholder="Cancel"
              variant="text"
              size="large"
              buttonColor="white"
              textColor="gray"
            />
            <CustomButton
              onClick={handleApply}
              placeholder="Apply"
              variant="contained"
              size="large"
              buttonColor="black"
              textColor="white"
            />
          </div>
        </div>
      </Popper>
    </>
  );
};

ElasticSearchFilter.propTypes = {
  availableFilters: PropTypes.object,
  filterValues: PropTypes.object,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  searchType: PropTypes.string,
  updateFilterValues: PropTypes.func,
  performSearch: PropTypes.func,
  keyCustomFields: PropTypes.array,
  restoreFilter: PropTypes.func
};

export default ElasticSearchFilter;
