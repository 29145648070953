import { makeStyles } from '@mui/styles';

const useStyles = makeStyles(() => ({
  modalForm: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
    minWidth: '1439px',
    height: '755px',
    padding: '0px 12px 12px 12px',
    border: '1px solid',
    borderColor: '#D8DBE0',
    backgroundColor: 'white',
    borderRadius: '16px',
    top: '56px',
    left: '50%',
    transform: 'translateX(-50%)',
    '&::-webkit-scrollbar': {
      width: '4px',
      height: '4px'
    },
    '&::-webkit-scrollbar-track': {
      boxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)',
      webkitBoxShadow: 'inset 0 0 6px rgba(0,0,0,0.00)'
    },
    '&::-webkit-scrollbar-thumb': {
      width: '4px',
      height: '4px',
      borderRadius: '200px',
      backgroundColor: '#A9A9A9'
    }
  },
  header: {
    paddingTop: '12px',
    position: 'sticky',
    top: '0px',
    backgroundColor: 'white',
    zIndex: '10',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  searchWithFilter: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
    paddingLeft: '12px',
    paddingRight: '12px'
  },
  closeIcon: {
    marginLeft: '12px',
    alignSelf: 'flex-end',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  contentWrapper: {
    maxHeight: '100vh',
    overflow: 'auto'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '12px',
    paddingRight: '12px'
  },
  contentAll: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  contentWithHeader: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  searchField: {
    color: '#111111',
    fontFamily: 'Nunito Sans, sans-serif',
    fontSize: '32px',
    fontWeight: 400,
    lineHeight: '48px',
    '&:before': {
      borderBottom: '3px solid #D1D5DB'
    },
    '&:after': {
      borderBottom: '3px solid #D1D5DB'
    },
    '&:hover:not(.Mui-disabled):before': {
      borderBottom: '3px solid #D1D5DB'
    }
  },
  buttonGroup: {
    height: '32px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  searchTypeGroup: {
    height: '32px',
    gap: '24px',
    '& .MuiToggleButtonGroup-lastButton.Mui-disabled, & .MuiToggleButtonGroup-middleButton.Mui-disabled':
      {
        borderLeft: '1px solid rgba(0, 0, 0, 0.12)'
      }
  },
  searchTypeItem: {
    height: '32px',
    padding: '4px 16px',
    backgroundColor: '#F7F9FF',
    borderRadius: '5px !important',
    border: 'none',
    textTransform: 'none',
    color: '#111111',
    fontFamily: 'Nunito Sans, sans-serif',
    fontSize: '15px',
    fontWeight: 400,
    lineHeight: '24px'
  },
  searchTypeSelected: {
    backgroundColor: '#7F1D1D !important',
    color: '#FFFFFF !important'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    width: '680px',
    border: '1px solid',
    borderColor: '#F3F4F6',
    borderRadius: '16px',
    padding: '12px 24px',
    gap: '4px'
  },
  cardFullWidth: {
    display: 'flex',
    flexDirection: 'column',
    border: '1px solid',
    borderColor: '#F3F4F6',
    borderRadius: '16px',
    padding: '12px 24px',
    gap: '4px'
  },
  cardTitleGroup: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center'
  },
  reportCardHeaderGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  reportCardTitleGroup: {
    display: 'flex',
    gap: '12px',
    alignItems: 'center',
    maxWidth: '800px'
  },
  cardTitle: {
    color: '#111111',
    fontFamily: 'Nunito Sans, sans-serif',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '48px'
  },
  cardTitleWithWrap: {
    color: '#111111',
    fontFamily: 'Nunito Sans, sans-serif',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '48px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px'
  },
  cardsTable: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: '22px',
    alignItems: 'flex-start'
  },
  cardsList: {
    display: 'flex',
    flexDirection: 'column',
    gap: '22px'
  },
  cardContentRow: {
    display: 'flex',
    gap: '8px',
    alignItems: 'start'
  },
  cardPdfContentRow: {
    display: 'flex',
    alignItems: 'start'
  },
  filedName: {
    color: '#9CA3AF',
    fontFamily: 'Nunito Sans, sans-serif',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '24px'
  },
  filedPage: {
    minWidth: '65px',
    color: '#9CA3AF',
    fontFamily: 'Nunito Sans, sans-serif',
    fontSize: '13px',
    fontWeight: 400,
    lineHeight: '24px'
  },
  filedValue: {
    color: '#000000',
    fontFamily: 'Nunito Sans, sans-serif',
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px'
  },
  filedSecondary: {
    color: '#9CA3AF',
    fontFamily: 'Nunito Sans, sans-serif',
    fontSize: '13px',
    fontWeight: 600,
    lineHeight: '24px'
  },
  searchValueStyle: {
    padding: '4px',
    borderRadius: '4px',
    fontStyle: 'italic',
    backgroundColor: '#FFDBDB'
  },
  reportType: {
    color: '#0A2705',
    fontWeight: 600,
    lineHeight: '21px',
    borderRadius: '8px',
    padding: '4px 12px 4px 12px',
    display: 'flex',
    alignItems: 'center',
    gap: '4px'
  },
  circleIcon: {
    width: '12px',
    height: '12px'
  }
}));

export default useStyles;
