import { makeStyles } from '@mui/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: theme.palette.white,
    padding: '0px 80px',
    boxShadow: '0px 4px 16px 0px #00000012',
    width: '100vw',
    right: 'auto',
    left: 0
  },
  flexGrow: {
    flexGrow: 1
  },
  toolBar: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  rightGroup: {
    color: '#242423',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  searchGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    gap: '4px',
    color: theme.palette.black.secondary,
    marginRight: '24px',
    '&:hover': {
      cursor: 'pointer'
    }
  },
  search: {
    fontWeight: 600,
    lineHeight: '19.1px',
    letterSpacing: '0.33px'
  },
  logoutIcon: {
    marginRight: '8px'
  },
  logoutButton: {
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px',
    cursor: 'pointer',
    color: theme.palette.primary.light,
    marginRight: '40px'
  },
  tabPanel: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  tab: {
    color: theme.palette.text.primary,
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textDecoration: 'none !important'
  },
  tabsContainer: {
    display: 'flex',
    gap: 52
  },
  active: {
    fontSize: '16px',
    fontWeight: 400,
    lineHeight: '24px',
    textDecoration: 'none !important',
    color: '#a40606 !important',
    borderBottom: '2px solid #a40606'
  },
  logo: {
    width: 187,
    height: 30
  },
  freeTrial: {
    height: '48px',
    padding: '8px 32px',
    gap: '8px',
    borderRadius: '40px',
    backgroundColor: theme.palette.primary.light,
    opacity: '0px',
    textTransform: 'none',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '24px'
  }
}));

export default useStyles;
