import * as constants from './elastic.constants';
import { CLEAR_STATE } from './elastic.constants';

export const getKeyCustomFieldsSuccess = (keyCustomFields) => ({
  type: constants.GET_KEY_CUSTOM_FIELDS_SUCCESS,
  payload: keyCustomFields
});

export const getSearchResult = () => ({
  type: constants.GET_SEARCH_RESULT
});

export const getSearchResultSuccess = (searchResult, resetValues, filterUniqueValues) => ({
  type: constants.GET_SEARCH_RESULT_SUCCESS,
  payload: {
    searchResult,
    resetValues,
    filterUniqueValues
  }
});

export const setLoading = (value) => ({
  type: constants.SET_LOADING,
  payload: value
});

export const updateFilters = (filters) => ({
  type: constants.UPDATE_FILTERS,
  payload: filters
});

export const clearState = () => ({
  type: constants.CLEAR_STATE
});

export const updatePostsAvailableFilters = (value) => ({
  type: constants.UPDATE_POSTS_AVAILABLE_FILTERS,
  payload: value
});

export const getTagsSuccess = (tags) => ({
  type: constants.GET_TAGS_SUCCESS,
  payload: tags
});

export const searchPost = (value) => ({
  type: constants.SEARCH_POST,
  payload: value
});

export const getLastPosts = () => ({
  type: constants.GET_LAST_POSTS
});

export const getLastPostsSuccess = (posts) => ({
  type: constants.GET_LAST_POSTS_SUCCESS,
  payload: posts
});
