import axios from 'axios';

export const searchDealsInfo = async (params, cancelToken) => {
  const { data } = await axios.post('/search/deal-info', params, {
    cancelToken
  });
  return data;
};

export const searchDocumentContent = async (params, cancelToken) => {
  const { data } = await axios.post('/search/document-content', params, {
    cancelToken
  });
  return data;
};

export const searchReportsInfo = async (params, cancelToken) => {
  const { data } = await axios.post('/search/report-info', params, {
    cancelToken
  });
  return data;
};

export const searchReportContent = async (params, cancelToken) => {
  const { data } = await axios.post('/search/report-content', params, {
    cancelToken
  });
  return data;
};

export const searchTopicsInfo = async (params, cancelToken) => {
  const { data } = await axios.post('/search/topic-info', params, {
    cancelToken
  });
  return data;
};
