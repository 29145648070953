import React from 'react';
import { EditRounded, OnlinePredictionRounded } from '@mui/icons-material';
import { editIconProps, onlineIconProps } from './PostTable/sxProps';
import { STATUS_DRAFT, STATUS_LIVE } from '../../../utils/constants/stringConstants';

export const columnToFilterMap = {
  type: 'typeList',
  executionDateFrom: 'executionDateFrom',
  executionDateTo: 'executionDateTo'
};

export const typeColorMap = new Map([
  ['Topic report', '#7F1D1D'],
  ['Scorecard', '#78350F'],
  ['Market standards report', '#064E3B'],
  ['Manager report', '#1E3A8A'],
  ['Secondary', '#187D83'],
  ['Deal update', '#7A812E'],
  ['Fiery find', '#4C1D95'],
  ['Primary', '#831843'],
  ['About us', '#246913'],
  ['Other', '#111827']
]);

export const typeBackgroundColorMap = new Map([
  ['Topic report', '#FEF2F2'],
  ['Scorecard', '#FFFBEB'],
  ['Market standards report', '#ECFDF5'],
  ['Manager report', '#EFF6FF'],
  ['Secondary', '#F2FCFD'],
  ['Deal update', '#FFFDEE'],
  ['Fiery find', '#F5F3FF'],
  ['Primary', '#FDF2F8'],
  ['About us', '#EFFFEB'],
  ['Other', '#F9FAFB']
]);

export const circleColorMap = new Map([
  ['Topic report', '#DC2626'],
  ['Scorecard', '#D97706'],
  ['Market standards report', '#059669'],
  ['Manager report', '#2563EB'],
  ['Secondary', '#27DBDB'],
  ['Deal update', '#F0E93D'],
  ['Fiery find', '#7C3AED'],
  ['Primary', '#DB2777'],
  ['About us', '#246913'],
  ['Other', '#4B5563']
]);

export const postTypesMap = new Map([
  ['Topic report', 1],
  ['Scorecard', 2],
  ['Market standards report', 3],
  ['Manager report', 4],
  ['Secondary', 5],
  ['Deal update', 6],
  ['Fiery find', 7],
  ['Primary', 8],
  ['About us', 9],
  ['Other', 10]
]);

export const initFilters = {
  postTypes: [],
  tags: [],
  products: [],
  status: [STATUS_LIVE],
  createdFrom: '',
  createdTo: ''
};

export const statusTitleMap = new Map([
  [
    STATUS_LIVE,
    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      Show live posts <OnlinePredictionRounded sx={onlineIconProps} />
    </div>
  ],
  [
    STATUS_DRAFT,
    <div style={{ display: 'flex', gap: '8px', alignItems: 'center' }}>
      Show draft posts <EditRounded sx={editIconProps} />
    </div>
  ]
]);

export const ITEM_PER_PAGE = 15;

export const POSTED_DATE = 'postedDate';
