import React, { useContext, useEffect, useState } from 'react';
import { Input, LinearProgress, Modal, ToggleButton, ToggleButtonGroup } from '@mui/material';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { useLocation, useNavigate } from 'react-router-dom';
import { CloseOutlined } from '@mui/icons-material';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import useStyles from './styles';
import modalStyles from '../../assets/scss/modalWindow';
import { searchTypes } from '../../utils/constants/listsConstants';
import {
  SEARCH_ALL,
  SEARCH_DEALS,
  SEARCH_PDF_CONTENT,
  SEARCH_REPORTS,
  SEARCH_TOPICS
} from '../../utils/constants/stringConstants';
import { onPressEnter } from '../../utils/utils';
import {
  searchDealsInfo,
  searchDocumentContent,
  searchReportsInfo,
  searchTopicsInfo
} from '../../services/api/search';
import { showAlert } from '../../redux/actions';
import { trimValue } from '../../utils/common';
import ReportCard from './ReportCard';
import ReportContentCard from './ReportContentCard';
import DocumentContentCard from './DocumentContentCard';
import DealCard from './DealCard';
import TopicCard from './TopicCard';
import ElasticSearchFilter from './ElasticSearchFilter/ElasticSearchFilter';
import ElasticContext from './context/elastic.context';
import * as actionsCreator from './context/elastic.actions';

const ElasticSearchModal = ({ open, onClose }) => {
  const classes = useStyles();
  const modalClasses = modalStyles();
  const {
    state: { searchResult, availableFilters, filterValues, keyCustomFields },
    dispatch
  } = useContext(ElasticContext);
  const location = useLocation();
  const navigate = useNavigate();
  const [searchType, setSearchType] = useState('Deals');
  const [searchPhrase, setSearchPhrase] = useState('');
  const [searchValue, setSearchValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [cancelSources, setCancelSources] = useState([]);
  const dispatchRedux = useDispatch();
  const [formState, setFormState] = useState({
    filterValues: {
      products: [],
      deals: [],
      currencies: [],
      reportTypes: [],
      customFieldMap: new Map()
    }
  });

  const cancelRequests = () => {
    cancelSources.forEach((source) => source.cancel());
    setCancelSources([]);
  };

  const handleClose = () => {
    cancelRequests();
    setSearchValue('');
    setSearchPhrase('');
    setLoading(false);
    dispatch(actionsCreator.clearState());
    onClose();
  };

  const updateNavigateUrl = (searchTypeParam, searchValueParam) => {
    navigate(
      `${location.pathname}?globalSearchModal=true&searchType=${searchTypeParam}&searchValue=${searchValueParam}`
    );
  };

  const handleChange = ({ target }) => {
    cancelRequests();
    setSearchValue(target.value);
    updateNavigateUrl(trimValue(searchType), trimValue(target.value));
    setLoading(false);
  };

  const restoreFilter = () => {
    setFormState(() => ({
      filterValues
    }));
  };

  const updateFilterValues = (name, value, filedId) => {
    if (name === 'customFieldMap') {
      setFormState((prevFormState) => {
        const updatedCustomFieldMap = new Map(prevFormState.filterValues.customFieldMap);
        updatedCustomFieldMap.set(filedId, value);

        return {
          ...prevFormState,
          filterValues: {
            ...prevFormState.filterValues,
            customFieldMap: updatedCustomFieldMap
          }
        };
      });
    } else {
      setFormState((prevFormState) => ({
        ...prevFormState,
        filterValues: {
          ...prevFormState.filterValues,
          [name]: value
        }
      }));
    }
  };

  const getRequestParams = () => {
    const customFieldsMap = new Map(
      [...formState.filterValues.customFieldMap]
        .map(([key, values]) => {
          const filedValues = values.map((item) => item.id);
          if (filedValues.length > 0) {
            return [
              key,
              {
                field: keyCustomFields.get(key),
                operator: 'isAnyOf',
                value: filedValues
              }
            ];
          }
          return null;
        })
        .filter(Boolean)
    );
    const customFieldsObject = Object.fromEntries(customFieldsMap);

    return {
      search: searchPhrase,
      productIds: formState.filterValues.products?.map((item) => item.id) || [],
      dealIds: formState.filterValues.deals?.map((item) => item.id) || [],
      currencies: formState.filterValues.currencies?.map((item) => item.id) || [],
      reportTypes: formState.filterValues.reportTypes?.map((item) => item.id) || [],
      ...(customFieldsMap.size > 0 && { customFields: customFieldsObject })
    };
  };

  const searchByType = async (searchMethod, cardType, resetValues) => {
    if (!searchPhrase || searchPhrase.length < 3) {
      return;
    }
    dispatch(actionsCreator.getSearchResult());
    dispatch(actionsCreator.updateFilters(formState.filterValues));
    setLoading(true);
    try {
      const source = axios.CancelToken.source();
      setCancelSources((prevSources) => [...prevSources, source]);
      const data = await searchMethod(getRequestParams(), source.token);
      const result = data?.result.map((item) => ({
        ...item,
        cardType,
        ...(cardType !== SEARCH_TOPICS && { valueMap: new Map(Object.entries(item.valueMap)) })
      }));
      data.filterUniqueValues.currencies = data.filterUniqueValues?.currencies?.map((name) => ({
        id: name,
        name
      }));
      data.filterUniqueValues.reportTypes = data.filterUniqueValues?.reportTypes?.map((name) => ({
        id: name,
        name
      }));
      data.filterUniqueValues.customFieldMap = new Map(
        Object.entries(data.filterUniqueValues?.customFieldMap || {})
      );
      dispatch(actionsCreator.getSearchResultSuccess(result, resetValues, data.filterUniqueValues));
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatchRedux(
          showAlert({ isShown: true, type: 'error', message: error.response?.data?.message })
        );
        dispatch(actionsCreator.setLoading(false));
      }
    }
    setLoading(false);
  };

  const handleSearchTypeChange = ({ target }) => {
    cancelRequests();
    dispatch(actionsCreator.clearState());
    setSearchType(target.value);
    updateNavigateUrl(target.value, searchValue);
  };

  const performSearch = () => {
    switch (searchType) {
      case SEARCH_ALL:
        searchByType(searchDealsInfo, SEARCH_DEALS, false);
        searchByType(searchDocumentContent, SEARCH_PDF_CONTENT, false);
        searchByType(searchTopicsInfo, SEARCH_TOPICS, false);
        searchByType(searchReportsInfo, SEARCH_REPORTS, false);
        break;
      case SEARCH_DEALS:
        searchByType(searchDealsInfo, SEARCH_DEALS, true);
        break;
      case SEARCH_PDF_CONTENT:
        searchByType(searchDocumentContent, SEARCH_PDF_CONTENT, true);
        break;
      case SEARCH_TOPICS:
        searchByType(searchTopicsInfo, SEARCH_TOPICS, true);
        break;
      case SEARCH_REPORTS:
        searchByType(searchReportsInfo, SEARCH_REPORTS, true);
        break;
      default:
    }
  };

  useEffect(() => {
    performSearch();
  }, [searchType, searchPhrase]);

  useEffect(() => {
    if (open) {
      const searchParams = new URLSearchParams(location.search);
      const searchValueParam = searchParams.get('searchValue');
      if (!!searchValueParam) {
        setSearchValue(trimValue(searchValueParam));
        setSearchPhrase(trimValue(searchValueParam));
      }
      const searchTypeParam = searchParams.get('searchType');
      if (!!searchTypeParam) {
        setSearchType(searchTypeParam);
      }
    } else {
      setSearchValue('');
      setSearchPhrase('');
      setLoading(false);
      setSearchType('Deals');
      dispatch(actionsCreator.clearState());
    }
  }, [open]);

  const renderCard = (cardType, data) => {
    switch (cardType) {
      case SEARCH_DEALS:
        return <DealCard data={data} searchValue={searchValue} />;
      case SEARCH_PDF_CONTENT:
        return <DocumentContentCard data={data} searchValue={searchValue} />;
      case SEARCH_TOPICS:
        return <TopicCard data={data} searchValue={searchValue} />;
      case SEARCH_REPORTS:
        return data.pdfContent ? (
          <ReportContentCard data={data} searchValue={searchValue} />
        ) : (
          <ReportCard data={data} searchValue={searchValue} />
        );
      default:
        return <div />;
    }
  };

  const getContentBlock = (cardType, data) => (
    <div
      className={
        cardType === SEARCH_PDF_CONTENT || cardType === SEARCH_REPORTS
          ? classes.cardsList
          : classes.cardsTable
      }
    >
      {data.map((data) => renderCard(cardType, data))}
    </div>
  );

  const getContentBlockWithHeader = (cardType) => (
    <div className={classes.contentWithHeader}>
      <div>{`${cardType} / ${
        searchResult.filter((item) => item.cardType === cardType).length
      }`}</div>
      {getContentBlock(
        cardType,
        searchResult.filter((item) => item.cardType === cardType)
      )}
    </div>
  );

  const getContentBlockForAll = () => (
    <div className={classes.contentAll}>
      {getContentBlockWithHeader(SEARCH_DEALS)}
      {getContentBlockWithHeader(SEARCH_PDF_CONTENT)}
      {getContentBlockWithHeader(SEARCH_TOPICS)}
      {getContentBlockWithHeader(SEARCH_REPORTS)}
    </div>
  );

  return (
    <Modal onClose={handleClose} open={open}>
      <div className={clsx(modalClasses.root, classes.modalForm)}>
        <div className={classes.header}>
          <CloseOutlined
            className={classes.closeIcon}
            sx={{ height: '20px', width: '20px' }}
            onClick={handleClose}
          />
          <div className={classes.searchWithFilter}>
            <Input
              name="search"
              value={searchValue}
              placeholder="Search"
              className={classes.searchField}
              onChange={handleChange}
              onKeyDown={onPressEnter(() => setSearchPhrase(trimValue(searchValue)))}
              onBlur={() => setSearchPhrase(trimValue(searchValue))}
            />
            <div className={classes.buttonGroup}>
              <ToggleButtonGroup
                disabled={loading}
                className={classes.searchTypeGroup}
                color="primary"
                value={searchType}
                exclusive
                onChange={handleSearchTypeChange}
                handleSearchTypeChange
                aria-label="Platform"
              >
                {searchTypes.map((type) => (
                  <ToggleButton
                    name="searchType"
                    className={clsx(
                      classes.searchTypeItem,
                      searchType === type && classes.searchTypeSelected
                    )}
                    value={type}
                  >
                    {type === searchType && !!searchResult.length
                      ? `${type} / ${searchResult.length}`
                      : type}
                  </ToggleButton>
                ))}
              </ToggleButtonGroup>
              {searchType !== SEARCH_ALL && (
                <ElasticSearchFilter
                  searchType={searchType}
                  loading={loading}
                  disabled={!searchResult.length}
                  availableFilters={availableFilters}
                  filterValues={formState.filterValues}
                  updateFilterValues={updateFilterValues}
                  performSearch={performSearch}
                  keyCustomFields={keyCustomFields}
                  restoreFilter={restoreFilter}
                />
              )}
            </div>
          </div>
        </div>
        <div className={classes.contentWrapper}>
          <div className={classes.content}>
            {loading && <LinearProgress />}
            {searchType !== SEARCH_ALL && getContentBlock(searchType, searchResult)}
            {searchType === SEARCH_ALL && searchResult.length > 0 && getContentBlockForAll()}
          </div>
        </div>
      </div>
    </Modal>
  );
};

ElasticSearchModal.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};

ElasticSearchModal.defaultProps = {
  open: false
};

export default ElasticSearchModal;
