import React, { useEffect, useRef, useState } from 'react';
import { Circle } from '@mui/icons-material';
import { Grid, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import CustomTooltip from '../CustomTooltip';
import {
  circleColorMap,
  typeBackgroundColorMap
} from '../../MainLayout/HomeLayout/PostPage/postConstants';
import useStyles from './styles';
import { highlightText } from '../../utils/common';

const ReportContentCard = ({ data, searchValue }) => {
  const classes = useStyles();
  const [isEllipsisActive, setIsEllipsisActive] = useState(false);
  const titleRef = useRef(null);

  useEffect(() => {
    if (titleRef.current) {
      setIsEllipsisActive(titleRef.current.scrollWidth > titleRef.current.clientWidth);
    }
  }, [data.reportName]);

  return (
    <div className={classes.cardFullWidth}>
      <div className={classes.reportCardHeaderGroup}>
        <div className={classes.reportCardTitleGroup}>
          <img src="/images/elasticsearch/pdf-icon.svg" alt="pdf-icon" />
          <CustomTooltip title={isEllipsisActive ? data.reportName : ''} placement="bottom-start">
            <Link
              className={classes.cardTitleWithWrap}
              href={`/${data.reportKey}`}
              underline="none"
              target="_blank"
              ref={titleRef}
            >
              {data.reportName}
            </Link>
          </CustomTooltip>
        </div>
        <Grid
          className={classes.reportType}
          style={{ backgroundColor: typeBackgroundColorMap.get(data?.reportType) }}
        >
          <Circle
            className={classes.circleIcon}
            style={{ color: circleColorMap.get(data?.reportType) }}
          />
          <Typography variant="body">{data?.reportType}</Typography>
        </Grid>
      </div>
      <div className={classes.cardContent}>
        {Array.from(data.valueMap).map(([key, value]) => (
          <div className={classes.cardPdfContentRow}>
            <div className={classes.filedPage}>Page {key} |</div>
            <div className={classes.filedValue}>{highlightText(value, searchValue)}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

ReportContentCard.propTypes = {
  data: PropTypes.object,
  searchValue: PropTypes.string
};

export default ReportContentCard;
