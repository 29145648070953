import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { logout } from 'src/redux/actions';
import { NavLink, useNavigate, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import UserPool from 'src/UserPool';
import { AppBar, Toolbar, Link, Button, Typography } from '@mui/material';
import Tabs from 'src/components/Tabs';
import { postAnalytics } from 'src/services/api/analytics';
import { SearchOutlined } from '@mui/icons-material';
import useStyles from './styles';
import {
  ADMIN_ROLE,
  EDITOR_ROLE,
  TRIAL_USER_ROLE,
  USER_ROLE,
  VIEWER_USER_ROLE,
  LOGOUT_EVENT
} from '../../utils/constants/stringConstants';
import ElasticSearchModal from '../ElasticSearchModal';
import ElasticModalPage from '../ElasticSearchModal/ElasticModalPage';

export const Header = ({ className, hideMenu, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const userRole = useSelector((state) => state.session.user.role);

  const adminTab = [
    { name: 'Home', link: '/home' },
    { name: 'Operations', link: '/operations' },
    { name: 'Deals', link: '/deal/page' },
    { name: 'Reports', link: '/reports' },
    { name: 'About us', link: '/about-us' },
    { name: 'Contact us', link: '/contact-us' }
  ];

  const editorTab = [
    { name: 'Home', link: '/home' },
    { name: 'Operations', link: '/operations' },
    { name: 'Deals', link: '/deal/page' },
    { name: 'Reports', link: '/reports' },
    { name: 'About us', link: '/about-us' },
    { name: 'Contact us', link: '/contact-us' }
  ];

  const userTab = [
    { name: 'Home', link: '/home' },
    { name: 'Deals', link: '/deal/page' },
    { name: 'Reports', link: '/reports' },
    { name: 'About us', link: '/about-us' },
    { name: 'Contact us', link: '/contact-us' }
  ];

  const [currentTabs, setCurrentTabs] = useState([]);
  const [elasticSearchOpen, setElasticSearchOpen] = useState(false);

  const getUserTab = () => {
    switch (userRole) {
      case ADMIN_ROLE:
        return adminTab;
      case EDITOR_ROLE:
        return editorTab;
      case TRIAL_USER_ROLE:
      case USER_ROLE:
      case VIEWER_USER_ROLE:
        return userTab;

      default:
        return [];
    }
  };

  useEffect(() => {
    setCurrentTabs(getUserTab());
    const cognitoUser = UserPool.getCurrentUser();
    setCurrentTabs(getUserTab());
    if (location.pathname === '/auth/login') {
      cognitoUser?.signOut();
      dispatch(logout());
    }
  }, [location.pathname, userRole]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    if (searchParams.get('globalSearchModal') === 'true') {
      setElasticSearchOpen(true);
    }
  }, [location.search]);

  const postAnalyticsEventHandler = async () => {
    const params = {
      event: LOGOUT_EVENT
    };

    await postAnalytics(params);
  };

  const handleLogout = async () => {
    await postAnalyticsEventHandler();
    const cognitoUser = UserPool.getCurrentUser();
    cognitoUser.signOut();
    dispatch(logout());
    const showAlert = localStorage.getItem('showAlert');
    const userSettings = localStorage.getItem('userSettings');
    localStorage.clear();
    localStorage.setItem('showAlert', showAlert);
    localStorage.setItem('userSettings', userSettings);
    navigate('/home');
    window.scrollTo(0, 0);
  };

  const handleLogin = () => {
    navigate('/auth/login');
  };

  return (
    <AppBar {...rest} className={clsx(classes.root, className)}>
      <ElasticModalPage
        open={elasticSearchOpen}
        onClose={() => {
          navigate(location.pathname);
          setElasticSearchOpen(false);
        }}
      />
      <Toolbar className={classes.toolBar}>
        <div className={classes.headerContainer}>
          <Link className={classes.companyName} to="/home" component={NavLink}>
            <img src="/images/logos/dealscribe_logo.png" alt="Home page" className={classes.logo} />
          </Link>
          {!hideMenu && (
            <Tabs
              tabs={currentTabs}
              normalStyle={classes.tab}
              activeStyle={classes.active}
              containerStyle={classes.tabsContainer}
            />
          )}
          {!hideMenu && (
            <div className={classes.rightGroup}>
              {!!userRole && (
                <div
                  className={classes.searchGroup}
                  onClick={() => {
                    navigate(`${location.pathname}?globalSearchModal=true`);
                    setElasticSearchOpen(!elasticSearchOpen);
                  }}
                >
                  <SearchOutlined sx={{ width: '14.58px', height: '14.58px' }} />
                  <Typography className={classes.search} color="inherit">
                    Search
                  </Typography>
                </div>
              )}
              {!!userRole ? (
                <Typography className={classes.logoutButton} color="inherit" onClick={handleLogout}>
                  Sign out
                </Typography>
              ) : (
                <Typography
                  id="signIn-link"
                  className={classes.logoutButton}
                  color="inherit"
                  onClick={handleLogin}
                >
                  Sign in
                </Typography>
              )}

              {!userRole && (
                <Button href="/auth/sign-up" variant="contained" className={classes.freeTrial}>
                  Free trial
                </Button>
              )}
            </div>
          )}
        </div>
      </Toolbar>
    </AppBar>
  );
};

Header.propTypes = {
  className: PropTypes.string,
  hideMenu: PropTypes.bool
};
